@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 0 169 224;    /* #00A9E0 */
    --color-accent: 245 124 0;     /* #F57C00 */
    --color-secondary: 176 190 197; /* #B0BEC5 */
  }

  body {
    @apply bg-light-primary dark:bg-dark-primary
           text-secondary-900 dark:text-secondary-100;
  }
}

@layer utilities {
  .glass-light {
    @apply bg-white/80 backdrop-blur-lg border border-white/20;
  }
  
  .glass-dark {
    @apply bg-dark-secondary/80 backdrop-blur-lg border border-secondary-800/20;
  }

  .glass {
    @apply glass-light dark:glass-dark;
  }
}
